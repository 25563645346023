.mediaPlayer {
  video {
    max-height: calc(100vh - 39px);
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .mediaPlayer {
    video {
      width: 380px !important; /* Adjust as needed */
    }
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .mediaPlayer {
    video {
      width: 425px !important; /* Adjust as needed */
    }
  }
}

@media (min-width: 1920px) {
  .mediaPlayer {
    video {
      width: 667px !important; /* Adjust as needed */
    }
  }
}