.container {
  width: 63%;
}
.expendedContainer {
  width: 41%;
}
.mediaContainer {
  position: relative;
}
.footer {
  bottom: 0;
  position: absolute;
}
.footerAction {
  bottom: 17px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
}
.childContainer {
  display: flex;
  width: 667px;
  flex-direction: column;
}


@media (min-width: 1024px) and (max-width: 1279px) {
    .childContainer {
        width: 380px; /* Adjust as needed */
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .childContainer {
        width: 425px; /* Adjust as needed */
    }
}

@media (min-width: 1920px) {
    .childContainer {
        width: 667px; /* Adjust as needed */
    }
}

.mainReelSection {
  position: relative;
}